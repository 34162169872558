import React from 'react';
import { css } from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import { Placement } from '../../../types/placement.ts';

const arrow = (
  <svg display="block" width="8" height="4" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 .485c-.552-.647-1.448-.647-2 0L0 4h8L5 .485Z" fill="#000" />
  </svg>
);

const defaultNegativeOffset = -4;

const bottomArrowConfig = {
  top: defaultNegativeOffset,
  rotate: null,
} as const;

const leftArrowConfig = {
  right: defaultNegativeOffset,
  rotate: css`
    transform: rotate(90deg);
  `,
} as const;

const topArrowConfig = {
  bottom: defaultNegativeOffset,
  rotate: css`
    transform: rotate(180deg);
  `,
} as const;

const rightArrowConfig = {
  left: defaultNegativeOffset,
  rotate: css`
    transform: rotate(270deg);
  `,
} as const;

type ArrowConfig = {
  readonly bottom?: string | number;
  readonly left?: string | number;
  readonly right?: string | number;
  readonly top?: string | number;
  readonly rotate: ReturnType<typeof css> | null;
};

const placementToArrowConfigMapping: Record<Placement, ArrowConfig> = {
  bottom: bottomArrowConfig,
  'bottom-end': {
    ...bottomArrowConfig,
    left: 'initial !important',
    right: '8px',
  },
  'bottom-start': {
    ...bottomArrowConfig,
    left: '8px !important',
  },
  top: topArrowConfig,
  'top-end': {
    ...topArrowConfig,
    left: 'initial !important',
    right: '8px',
  },
  'top-start': {
    ...topArrowConfig,
    left: '8px !important',
  },
  left: leftArrowConfig,
  'left-end': {
    ...leftArrowConfig,
    bottom: '8px',
    top: 'initial !important',
  },
  'left-start': {
    ...leftArrowConfig,
    top: '8px !important',
  },
  right: rightArrowConfig,
  'right-end': {
    ...rightArrowConfig,
    bottom: '8px',
    top: 'initial !important',
  },
  'right-start': {
    ...rightArrowConfig,
    top: '8px !important',
  },
};

export const Arrow: React.FC<{
  readonly placement: Placement;
}> = ({ placement }) => {
  const { rotate, ...rest } = placementToArrowConfigMapping[placement];

  return (
    <Box
      data-popper-arrow=""
      display="inline-flex"
      position="absolute"
      height={8}
      width={8}
      css={
        [
          'top-start',
          'top-end',
          'bottom-start',
          'bottom-end',
          'left-start',
          'left-end',
          'right-start',
          'right-end',
        ].includes(placement)
          ? 'transform: none !important;'
          : undefined
      }
      {...rest}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-base-to-string */}
      <Box display="inline-flex" height={8} width={8} css={`${rotate};`}>
        {arrow}
      </Box>
    </Box>
  );
};

Arrow.displayName = 'Arrow';
